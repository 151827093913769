.sendbird-create-channel--content {
  width: 480px;
  max-height: 552px;
}

.sendbird-create-channel--scroll {
  height: 360px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.sendbird--mobile-mode .sendbird-create-channel--scroll {
  height: calc(100vh - 200px);
  height: calc(var(--sendbird-vh, 1vh) * 100 - 200px);
}