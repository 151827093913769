.sendbird-chat-header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  min-height: 64px;
  padding-left: 20px;
  padding-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
}
.sendbird-theme--light .sendbird-chat-header {
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
  background-color: var(--sendbird-light-background-50);
}
.sendbird-theme--dark .sendbird-chat-header {
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
  background-color: var(--sendbird-dark-background-600);
}
.sendbird-chat-header__left {
  margin-right: 24px;
}
.sendbird-chat-header__left, .sendbird-chat-header__right {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sendbird-chat-header .sendbird-chat-header__left__title {
  margin-left: 8px;
  margin-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 780px;
}
.sendbird--mobile-mode .sendbird-chat-header .sendbird-chat-header__left__title {
  max-width: calc(100vw - 240px);
}
.sendbird-chat-header .sendbird-chat-header__left__subtitle {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sendbird-chat-header .sendbird-chat-header__right__mute {
  margin-right: 26px;
}
.sendbird-chat-header .sendbird-iconbutton--pressed {
  background-color: inherit;
}
.sendbird-chat-header .sendbird-chat-header__right__info {
  margin-left: 16px;
}

.sendbird-chat-header__icon_back {
  margin-right: 8px;
}