.sendbird-channel-settings {
  height: 100%;
  width: 320px;
  display: inline-block;
  box-sizing: border-box;
}
.sendbird--mobile-mode .sendbird-channel-settings {
  width: 100%;
}
.sendbird-theme--light .sendbird-channel-settings {
  background-color: var(--sendbird-light-background-50);
  border-left: 1px solid var(--sendbird-light-onlight-04);
}
.sendbird-theme--dark .sendbird-channel-settings {
  background-color: var(--sendbird-dark-background-600);
  border-left: 1px solid var(--sendbird-dark-background-600);
}
.sendbird-channel-settings .sendbird-channel-settings__header-icon {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}
.sendbird-theme--light .sendbird-channel-settings .sendbird-channel-settings__header-icon .sendbird-channel-settings__close-icon path {
  fill: var(--sendbird-light-onlight-01);
}
.sendbird-theme--dark .sendbird-channel-settings .sendbird-channel-settings__header-icon .sendbird-channel-settings__close-icon path {
  fill: var(--sendbird-dark-ondark-01);
}
.sendbird-channel-settings .sendbird-channel-settings__members .sendbird-badge__text {
  margin-top: -2px;
}
.sendbird-channel-settings .sendbird-channel-settings__header {
  height: 64px;
  min-height: 64px;
  position: relative;
  padding: 20px 24px;
  box-sizing: border-box;
}
.sendbird-theme--light .sendbird-channel-settings .sendbird-channel-settings__header {
  border-bottom: solid 1px var(--sendbird-light-onlight-04);
}
.sendbird-theme--dark .sendbird-channel-settings .sendbird-channel-settings__header {
  border-bottom: solid 1px var(--sendbird-dark-ondark-04);
}
.sendbird-channel-settings .sendbird-channel-settings__scroll-area {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: calc(100% - 64px);
  overflow-y: auto;
}
.sendbird-channel-settings .sendbird-channel-settings__panel-item {
  cursor: pointer;
  position: relative;
  padding: 12px 22px 10px 56px;
}
.sendbird-theme--light .sendbird-channel-settings .sendbird-channel-settings__panel-item {
  background-color: var(--sendbird-light-background-50);
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
}
.sendbird-theme--dark .sendbird-channel-settings .sendbird-channel-settings__panel-item {
  background-color: var(--sendbird-dark-background-600);
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
}
.sendbird-channel-settings .sendbird-channel-settings__panel-item:focus {
  outline: none;
}
.sendbird-channel-settings .sendbird-channel-settings__panel-item.sendbird-channel-settings__panel-item__disabled {
  cursor: not-allowed;
}
.sendbird-channel-settings .sendbird-channel-settings__panel-icon-left,
.sendbird-channel-settings .sendbird-channel-settings__panel-icon-right {
  position: absolute;
  top: 10px;
}
.sendbird-channel-settings .sendbird-channel-settings__panel-icon-left {
  left: 16px;
}
.sendbird-channel-settings .sendbird-channel-settings__panel-icon-right {
  right: 16px;
}
.sendbird-channel-settings .sendbird-channel-settings__panel-icon--open {
  transform: rotate(90deg);
}
.sendbird-theme--light .sendbird-channel-settings .sendbird-channel-settings__panel-icon--chevron path {
  fill: var(--sendbird-light-onlight-01);
}
.sendbird-theme--dark .sendbird-channel-settings .sendbird-channel-settings__panel-icon--chevron path {
  fill: var(--sendbird-dark-ondark-01);
}