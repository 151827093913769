.sendbird-fileviewer__header__right__actions__download,
.sendbird-fileviewer__header__right__actions__delete,
.sendbird-fileviewer__header__right__actions__close {
  width: 64px;
  padding: 21px;
  box-sizing: border-box;
  cursor: pointer;
}
.sendbird-fileviewer__header__right__actions__download .disabled,
.sendbird-fileviewer__header__right__actions__delete .disabled,
.sendbird-fileviewer__header__right__actions__close .disabled {
  cursor: not-allowed;
}

.sendbird-fileviewer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
}
.sendbird-theme--light .sendbird-fileviewer {
  background-color: var(--sendbird-light-background-50);
}
.sendbird-theme--dark .sendbird-fileviewer {
  background-color: var(--sendbird-dark-background-600);
}
.sendbird-fileviewer .sendbird-fileviewer__header {
  height: 64px;
  min-height: 64px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.sendbird--mobile-mode .sendbird-fileviewer .sendbird-fileviewer__header {
  position: relative;
}
.sendbird-theme--light .sendbird-fileviewer .sendbird-fileviewer__header {
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
}
.sendbird-theme--dark .sendbird-fileviewer .sendbird-fileviewer__header {
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
}
.sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 64px;
  padding: 16px 20px;
}
.sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__avatar {
  margin-right: 8px;
}
.sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__filename {
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.sendbird--mobile-mode .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__filename {
  position: absolute;
  left: 64px;
  top: 10px;
  display: block;
  max-width: calc(100vw - 240px);
}
.sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__sender-name {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  margin-left: 8px;
  padding-top: 5px;
}
.sendbird--mobile-mode .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__left .sendbird-fileviewer__header__left__sender-name {
  position: absolute;
  left: 56px;
  top: 24px;
}
.sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__right {
  display: flex;
  width: 160px;
  justify-content: flex-end;
}
.sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__right .sendbird-fileviewer__header__right__actions {
  right: 0;
  display: flex;
  flex-direction: row;
}
.sendbird-theme--light .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__right .sendbird-fileviewer__header__right__actions__close {
  border-left: 1px solid var(--sendbird-light-onlight-04);
}
.sendbird-theme--dark .sendbird-fileviewer .sendbird-fileviewer__header .sendbird-fileviewer__header__right .sendbird-fileviewer__header__right__actions__close {
  border-left: 1px solid var(--sendbird-dark-ondark-04);
}
.sendbird-fileviewer .sendbird-fileviewer__content {
  height: calc(100% - 72px);
  margin-top: 8px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sendbird-fileviewer .sendbird-fileviewer__content .sendbird-fileviewer__content__video {
  max-width: 100%;
  max-height: 100%;
}
.sendbird-fileviewer .sendbird-fileviewer__content .sendbird-fileviewer__content__img {
  max-width: 90%;
  max-height: 90%;
}
.sendbird-fileviewer .sendbird-fileviewer__content .sendbird-fileviewer__content__unsupported {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}