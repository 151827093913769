.sendbird-message-input-wrapper {
  position: relative;
  width: 100%;
}
.sendbird-message-input-wrapper .sendbird-message-input-wrapper__quote-message-input {
  position: relative;
  width: 100%;
}
.sendbird-theme--light .sendbird-message-input-wrapper .sendbird-message-input-wrapper__quote-message-input {
  border-top: solid 1px var(--sendbird-light-onlight-04);
}
.sendbird-theme--dark .sendbird-message-input-wrapper .sendbird-message-input-wrapper__quote-message-input {
  border-top: solid 1px var(--sendbird-dark-ondark-04);
}
.sendbird-message-input-wrapper .sendbird-message-input-wrapper__message-input {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 24px;
}