.sendbird-openchannel-settings {
  height: 100%;
  width: 320px;
  display: inline-block;
  overflow-y: scroll;
  position: relative;
}
.sendbird--mobile-mode .sendbird-openchannel-settings {
  width: 100%;
}
.sendbird-theme--light .sendbird-openchannel-settings {
  background-color: var(--sendbird-light-background-50);
  border-left: 1px solid var(--sendbird-light-onlight-04);
}
.sendbird-theme--dark .sendbird-openchannel-settings {
  background-color: var(--sendbird-dark-background-600);
  border-left: 1px solid var(--sendbird-dark-ondark-04);
}
.sendbird-openchannel-settings .sendbird-participants-accordion__footer {
  text-align: left;
  padding-top: 16px;
  padding-left: 16px;
}
.sendbird-openchannel-settings .sendbird-openchannel-settings__operator-accordion-icon {
  position: absolute;
  left: 14px;
}
.sendbird-openchannel-settings .sendbird-openchannel-settings__accordion-icon,
.sendbird-openchannel-settings .sendbird-openchannel-settings__panel-icon__delete {
  position: absolute;
  left: 16px;
}
.sendbird-openchannel-settings .sendbird-openchannel-settings__participant {
  height: 100%;
}
.sendbird-openchannel-settings .sendbird-openchannel-settings__participant .sendbird-openchannel-settings__participant-list {
  padding-top: 64px;
  height: calc(100% - 64px);
  overflow-y: scroll;
}
.sendbird-openchannel-settings .sendbird-openchannel-settings__participant .sendbird-openchannel-settings__header {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.sendbird-theme--light .sendbird-openchannel-settings .sendbird-openchannel-settings__participant .sendbird-openchannel-settings__header {
  background-color: var(--sendbird-light-background-50);
}
.sendbird-theme--dark .sendbird-openchannel-settings .sendbird-openchannel-settings__participant .sendbird-openchannel-settings__header {
  background-color: var(--sendbird-dark-background-600);
}
.sendbird-openchannel-settings .sendbird-participants-accordion,
.sendbird-openchannel-settings .sendbird-openchannel-settings__delete-channel {
  text-align: left;
}
.sendbird-openchannel-settings .sendbird-openchannel-settings__members .sendbird-badge__text {
  margin-top: -2px;
}
.sendbird-openchannel-settings .sendbird-openchannel-settings__header {
  height: 64px;
  min-height: 64px;
  position: relative;
  padding: 20px 24px;
  box-sizing: border-box;
  text-align: left;
}
.sendbird-theme--light .sendbird-openchannel-settings .sendbird-openchannel-settings__header {
  border-bottom: solid 1px var(--sendbird-light-onlight-04);
}
.sendbird-theme--dark .sendbird-openchannel-settings .sendbird-openchannel-settings__header {
  border-bottom: solid 1px var(--sendbird-dark-ondark-04);
}
.sendbird-openchannel-settings .sendbird-openchannel-settings__close-icon {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 16px;
}
.sendbird-theme--light .sendbird-openchannel-settings .sendbird-openchannel-settings__close-icon path {
  fill: var(--sendbird-light-onlight-01);
}
.sendbird-theme--dark .sendbird-openchannel-settings .sendbird-openchannel-settings__close-icon path {
  fill: var(--sendbird-dark-ondark-01);
}
.sendbird-openchannel-settings .sendbird-openchannel-settings__placeholder {
  margin-top: 40%;
}
.sendbird-openchannel-settings .sendbird-openchannel-settings__url {
  padding: 16px;
  box-sizing: border-box;
  position: relative;
}
.sendbird-openchannel-settings .sendbird-openchannel-settings__url:hover .sendbird-openchannel-settings__copy-icon {
  display: inline-block;
}
.sendbird-openchannel-settings .sendbird-openchannel-settings__url .sendbird-openchannel-settings__copy-icon {
  cursor: pointer;
  position: absolute;
  display: none;
  top: 10px;
  right: 18px;
}
.sendbird-openchannel-settings .sendbird-openchannel-settings__url .sendbird-openchannel-settings__url-value {
  margin-top: 8px;
}
.sendbird-theme--light .sendbird-openchannel-settings .sendbird-openchannel-settings__url .sendbird-openchannel-settings__url-value {
  color: var(--sendbird-light-onlight-01);
}
.sendbird-theme--dark .sendbird-openchannel-settings .sendbird-openchannel-settings__url .sendbird-openchannel-settings__url-value {
  color: var(--sendbird-dark-ondark-01);
}
.sendbird-openchannel-settings .sendbird-openchannel-settings__url .sendbird-openchannel-settings__url-value,
.sendbird-openchannel-settings .sendbird-openchannel-settings__url .sendbird-openchannel-settings__url-label {
  text-align: left;
  display: block;
  word-wrap: break-word;
}
.sendbird-theme--light .sendbird-openchannel-settings .sendbird-openchannel-settings__url {
  background-color: var(--sendbird-light-background-50);
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
}
.sendbird-theme--dark .sendbird-openchannel-settings .sendbird-openchannel-settings__url {
  background-color: var(--sendbird-dark-background-600);
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
}
.sendbird-openchannel-settings .sendbird-openchannel-settings__panel-item {
  display: block;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  padding: 12px 22px 10px 56px;
}
.sendbird-theme--light .sendbird-openchannel-settings .sendbird-openchannel-settings__panel-item {
  background-color: var(--sendbird-light-background-50);
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
}
.sendbird-theme--dark .sendbird-openchannel-settings .sendbird-openchannel-settings__panel-item {
  background-color: var(--sendbird-dark-background-600);
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
}
.sendbird-openchannel-settings .sendbird-openchannel-settings__panel-item:focus {
  outline: none;
}
.sendbird-openchannel-settings .sendbird-openchannel-settings__panel-item.sendbird-channel-settings__panel-item__disabled {
  cursor: not-allowed;
}
.sendbird-openchannel-settings .sendbird-participants-accordion__member {
  position: relative;
  padding: 12px 0px 12px 56px;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  text-align: left;
}
.sendbird-openchannel-settings .sendbird-participants-accordion__member .sendbird-label {
  max-width: 190px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sendbird-openchannel-settings .sendbird-participants-accordion__member .sendbird-participants-accordion__member__title.self-operator,
.sendbird-openchannel-settings .sendbird-participants-accordion__member .sendbird-participants-accordion__member__title.operator,
.sendbird-openchannel-settings .sendbird-participants-accordion__member .sendbird-participants-accordion__member__title.user-id {
  position: absolute;
  right: 16px;
}
.sendbird-openchannel-settings .sendbird-participants-accordion__member .sendbird-participants-accordion__member__action {
  display: none;
}
.sendbird-openchannel-settings .sendbird-participants-accordion__member:hover .sendbird-participants-accordion__member__title.operator,
.sendbird-openchannel-settings .sendbird-participants-accordion__member:hover .sendbird-participants-accordion__member__title.user-id {
  display: none;
}
.sendbird-openchannel-settings .sendbird-participants-accordion__member:hover .sendbird-participants-accordion__member__action {
  display: inline-block;
}
.sendbird-openchannel-settings .sendbird-participants-accordion__member-avatar {
  position: absolute;
  left: 16px;
  top: 10px;
  cursor: pointer;
}
.sendbird-openchannel-settings .sendbird-participants-accordion__member__action {
  position: absolute;
  display: none;
  top: 4px;
  right: 8px;
  cursor: pointer;
}
.sendbird-openchannel-settings .sendbird-openchannel-operator-list__footer,
.sendbird-openchannel-settings .sendbird-openchannel-participant-list__footer,
.sendbird-openchannel-settings .sendbird-channel-settings-muted-participant-list__footer,
.sendbird-openchannel-settings .sendbird-channel-settings-banned-user-list__footer {
  padding-top: 16px;
  display: flex;
  justify-content: left;
  padding-left: 12px;
}
.sendbird-openchannel-settings .sendbird-openchannel-operator-list__footer .sendbird-button:nth-child(2),
.sendbird-openchannel-settings .sendbird-openchannel-participant-list__footer .sendbird-button:nth-child(2),
.sendbird-openchannel-settings .sendbird-channel-settings-muted-participant-list__footer .sendbird-button:nth-child(2),
.sendbird-openchannel-settings .sendbird-channel-settings-banned-user-list__footer .sendbird-button:nth-child(2) {
  margin-left: 8px;
}

.sendbird-more-users__popup-scroll {
  max-height: 420px;
  overflow: scroll;
}