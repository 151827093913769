.sendbird-channel-list {
  width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.sendbird-theme--light .sendbird-channel-list {
  background-color: var(--sendbird-light-background-50);
}
.sendbird-theme--dark .sendbird-channel-list {
  background-color: var(--sendbird-dark-background-600);
}
.sendbird--mobile-mode .sendbird-channel-list {
  width: 100%;
}

.sendbird-channel-list__header {
  height: 64px;
  min-height: 64px;
}

.sendbird-channel-list__body {
  flex: 1 1 0;
  -ms-flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}